<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img
        :src="require('@/assets/avtala-logo-medium.png')"
        contain
        max-width="400"
        min-height="120"
        width="100%"
      />
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Adress',
          text: 'Gransbergsvägen 18<br>139 73 Djurhamn',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Telefon',
          text: '070 - 559 99 55<br>070 - 555 19 24',
        },
        {
          icon: 'mdi-email',
          title: 'E-post',
          text: 'info@avtala.se<br>support@avtala.se',
        },
      ],
    }),
  }
</script>
