<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        title="Kontakta oss"
        pace="6"
      >
        Undrar du över något, tveka inte att höra av dig till oss.
        Vi svarar gärna på dina frågor, och berättar mer om Avtala.
      </base-info-card>
      <!--
      <base-business-contact dense />
      -->
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessInfo',

    props: { dark: Boolean },

    data: () => ({
    }),
  }
</script>
